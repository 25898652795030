/* tslint:disable */
/* eslint-disable */
/**
 * JamiiTracker
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Authorization
 */
export interface Authorization {
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    'access_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    'expires'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizationApiResult
 */
export interface AuthorizationApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Authorization}
     * @memberof AuthorizationApiResult
     */
    'content'?: Authorization;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface BooleanApiResult
 */
export interface BooleanApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'content'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {Array<CompanyUser>}
     * @memberof Company
     */
    'users'?: Array<CompanyUser> | null;
    /**
     * 
     * @type {Array<Site>}
     * @memberof Company
     */
    'sites'?: Array<Site> | null;
}
/**
 * 
 * @export
 * @interface CompanyApiResult
 */
export interface CompanyApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Company}
     * @memberof CompanyApiResult
     */
    'content'?: Company;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyIReadOnlyCollectionApiResult
 */
export interface CompanyIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIReadOnlyCollectionApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyIReadOnlyCollectionApiResult
     */
    'content'?: Array<Company> | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyIReadOnlyCollectionApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyInsertDto
 */
export interface CompanyInsertDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyInsertDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyUpdateDto
 */
export interface CompanyUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'userId'?: string;
    /**
     * 
     * @type {Company}
     * @memberof CompanyUser
     */
    'company'?: Company;
    /**
     * 
     * @type {User}
     * @memberof CompanyUser
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface DashboardFilter
 */
export interface DashboardFilter {
    /**
     * 
     * @type {number}
     * @memberof DashboardFilter
     */
    'xMinutes'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardFilter
     */
    'gateways'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardFilter
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardFilter
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardFilter
     */
    'siteID'?: string;
}
/**
 * 
 * @export
 * @interface Gateway
 */
export interface Gateway {
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'siteId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'deviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Gateway
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Gateway
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {Array<Node>}
     * @memberof Gateway
     */
    'nodes'?: Array<Node> | null;
    /**
     * 
     * @type {Company}
     * @memberof Gateway
     */
    'company'?: Company;
    /**
     * 
     * @type {Site}
     * @memberof Gateway
     */
    'site'?: Site;
    /**
     * 
     * @type {GatewayConfig}
     * @memberof Gateway
     */
    'config'?: GatewayConfig;
}
/**
 * 
 * @export
 * @interface GatewayApiResult
 */
export interface GatewayApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof GatewayApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Gateway}
     * @memberof GatewayApiResult
     */
    'content'?: Gateway;
    /**
     * 
     * @type {string}
     * @memberof GatewayApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GatewayConfig
 */
export interface GatewayConfig {
    /**
     * 
     * @type {string}
     * @memberof GatewayConfig
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfig
     */
    'config'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfig
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {Gateway}
     * @memberof GatewayConfig
     */
    'gateway'?: Gateway;
}
/**
 * 
 * @export
 * @interface GatewayConfigApiResult
 */
export interface GatewayConfigApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof GatewayConfigApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {GatewayConfig}
     * @memberof GatewayConfigApiResult
     */
    'content'?: GatewayConfig;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GatewayIReadOnlyCollectionApiResult
 */
export interface GatewayIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof GatewayIReadOnlyCollectionApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<Gateway>}
     * @memberof GatewayIReadOnlyCollectionApiResult
     */
    'content'?: Array<Gateway> | null;
    /**
     * 
     * @type {string}
     * @memberof GatewayIReadOnlyCollectionApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GatewayInsertDto
 */
export interface GatewayInsertDto {
    /**
     * 
     * @type {string}
     * @memberof GatewayInsertDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayInsertDto
     */
    'siteId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GatewayInsertDto
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayInsertDto
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GatewayInsertDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GatewayUpdateDto
 */
export interface GatewayUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof GatewayUpdateDto
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayUpdateDto
     */
    'siteId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GatewayUpdateDto
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayUpdateDto
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GatewayUpdateDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayUpdateDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'deviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {Gateway}
     * @memberof Node
     */
    'gateway'?: Gateway;
    /**
     * 
     * @type {NodeConfig}
     * @memberof Node
     */
    'config'?: NodeConfig;
}
/**
 * 
 * @export
 * @interface NodeApiResult
 */
export interface NodeApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof NodeApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Node}
     * @memberof NodeApiResult
     */
    'content'?: Node;
    /**
     * 
     * @type {string}
     * @memberof NodeApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeConfig
 */
export interface NodeConfig {
    /**
     * 
     * @type {string}
     * @memberof NodeConfig
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeConfig
     */
    'config'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeConfig
     */
    'lastUpdated'?: string;
}
/**
 * 
 * @export
 * @interface NodeConfigApiResult
 */
export interface NodeConfigApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof NodeConfigApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {NodeConfig}
     * @memberof NodeConfigApiResult
     */
    'content'?: NodeConfig;
    /**
     * 
     * @type {string}
     * @memberof NodeConfigApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeEvent
 */
export interface NodeEvent {
    /**
     * 
     * @type {ObjectId}
     * @memberof NodeEvent
     */
    '_id'?: ObjectId;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'nodeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodeEvent
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'original'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'eventDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'eventData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'eventResult'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodeEvent
     */
    'eventCounter'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeEvent
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodeEvent
     */
    'recordNo'?: number | null;
}
/**
 * 
 * @export
 * @interface NodeEventApiResult
 */
export interface NodeEventApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof NodeEventApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {NodeEvent}
     * @memberof NodeEventApiResult
     */
    'content'?: NodeEvent;
    /**
     * 
     * @type {string}
     * @memberof NodeEventApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeEventIReadOnlyCollectionApiResult
 */
export interface NodeEventIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof NodeEventIReadOnlyCollectionApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<NodeEvent>}
     * @memberof NodeEventIReadOnlyCollectionApiResult
     */
    'content'?: Array<NodeEvent> | null;
    /**
     * 
     * @type {string}
     * @memberof NodeEventIReadOnlyCollectionApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeInsertDto
 */
export interface NodeInsertDto {
    /**
     * 
     * @type {string}
     * @memberof NodeInsertDto
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeInsertDto
     */
    'deviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeInsertDto
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeInsertDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeStat
 */
export interface NodeStat {
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'stock'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'change'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'gateway'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'time'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodeStat
     */
    'lowStock'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeStat
     */
    'event'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodeStat
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeStat
     */
    'startWeight'?: number;
    /**
     * 
     * @type {WeightNodeConfig}
     * @memberof NodeStat
     */
    'config'?: WeightNodeConfig;
    /**
     * 
     * @type {number}
     * @memberof NodeStat
     */
    'recordNo'?: number | null;
}
/**
 * 
 * @export
 * @interface NodeStatIReadOnlyCollectionApiResult
 */
export interface NodeStatIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof NodeStatIReadOnlyCollectionApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<NodeStat>}
     * @memberof NodeStatIReadOnlyCollectionApiResult
     */
    'content'?: Array<NodeStat> | null;
    /**
     * 
     * @type {string}
     * @memberof NodeStatIReadOnlyCollectionApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeUpdateDto
 */
export interface NodeUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof NodeUpdateDto
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeUpdateDto
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeUpdateDto
     */
    'deviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeUpdateDto
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeUpdateDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface NodeWithCurrentData
 */
export interface NodeWithCurrentData {
    /**
     * 
     * @type {string}
     * @memberof NodeWithCurrentData
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeWithCurrentData
     */
    'gatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeWithCurrentData
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeWithCurrentData
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeWithCurrentData
     */
    'deviceId'?: string | null;
    /**
     * 
     * @type {NodeConfig}
     * @memberof NodeWithCurrentData
     */
    'config'?: NodeConfig;
    /**
     * 
     * @type {NodeEvent}
     * @memberof NodeWithCurrentData
     */
    'lastEvent'?: NodeEvent;
}
/**
 * 
 * @export
 * @interface NodeWithCurrentDataIReadOnlyCollectionApiResult
 */
export interface NodeWithCurrentDataIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof NodeWithCurrentDataIReadOnlyCollectionApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<NodeWithCurrentData>}
     * @memberof NodeWithCurrentDataIReadOnlyCollectionApiResult
     */
    'content'?: Array<NodeWithCurrentData> | null;
    /**
     * 
     * @type {string}
     * @memberof NodeWithCurrentDataIReadOnlyCollectionApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectId
 */
export interface ObjectId {
    /**
     * 
     * @type {number}
     * @memberof ObjectId
     */
    'timestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectId
     * @deprecated
     */
    'machine'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectId
     * @deprecated
     */
    'pid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectId
     * @deprecated
     */
    'increment'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObjectId
     */
    'creationTime'?: string;
}
/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'companyId'?: string;
    /**
     * 
     * @type {Company}
     * @memberof Site
     */
    'company'?: Company;
}
/**
 * 
 * @export
 * @interface SiteApiResult
 */
export interface SiteApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof SiteApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Site}
     * @memberof SiteApiResult
     */
    'content'?: Site;
    /**
     * 
     * @type {string}
     * @memberof SiteApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SiteIReadOnlyCollectionApiResult
 */
export interface SiteIReadOnlyCollectionApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof SiteIReadOnlyCollectionApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<Site>}
     * @memberof SiteIReadOnlyCollectionApiResult
     */
    'content'?: Array<Site> | null;
    /**
     * 
     * @type {string}
     * @memberof SiteIReadOnlyCollectionApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SiteInsertDto
 */
export interface SiteInsertDto {
    /**
     * 
     * @type {string}
     * @memberof SiteInsertDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteInsertDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface SiteUpdateDto
 */
export interface SiteUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof SiteUpdateDto
     */
    'siteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteUpdateDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteUpdateDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'salt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'roles'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'failedAttempts'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'unlockDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<CompanyUser>}
     * @memberof User
     */
    'companies'?: Array<CompanyUser> | null;
}
/**
 * 
 * @export
 * @interface UserApiResult
 */
export interface UserApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof UserApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {User}
     * @memberof UserApiResult
     */
    'content'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserListApiResult
 */
export interface UserListApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof UserListApiResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserListApiResult
     */
    'content'?: Array<User> | null;
    /**
     * 
     * @type {string}
     * @memberof UserListApiResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface WeightNodeConfig
 */
export interface WeightNodeConfig {
    /**
     * 
     * @type {number}
     * @memberof WeightNodeConfig
     */
    'emptyBucketWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeightNodeConfig
     */
    'singleItemWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeightNodeConfig
     */
    'singleItemTolerance'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeightNodeConfig
     */
    'scaleTolerance'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeightNodeConfig
     */
    'scaleType'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeightNodeConfig
     */
    'minStock'?: number;
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyDeleteById', 'id', id)
            const localVarPath = `/Company/Delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Company/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyGetById', 'id', id)
            const localVarPath = `/Company/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetPage: async (page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Company/GetPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyInsertDto} [companyInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsert: async (companyInsertDto?: CompanyInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Company/Insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyUpdateDto} [companyUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate: async (companyUpdateDto?: CompanyUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Company/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetPage(page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetPage(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyInsertDto} [companyInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInsert(companyInsertDto?: CompanyInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInsert(companyInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyUpdateDto} [companyUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdate(companyUpdateDto?: CompanyUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdate(companyUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteById(id: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.companyDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGet(options?: any): AxiosPromise<CompanyIReadOnlyCollectionApiResult> {
            return localVarFp.companyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetById(id: string, options?: any): AxiosPromise<CompanyApiResult> {
            return localVarFp.companyGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetPage(page?: number, perPage?: number, options?: any): AxiosPromise<CompanyIReadOnlyCollectionApiResult> {
            return localVarFp.companyGetPage(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyInsertDto} [companyInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsert(companyInsertDto?: CompanyInsertDto, options?: any): AxiosPromise<CompanyApiResult> {
            return localVarFp.companyInsert(companyInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyUpdateDto} [companyUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate(companyUpdateDto?: CompanyUpdateDto, options?: any): AxiosPromise<CompanyApiResult> {
            return localVarFp.companyUpdate(companyUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDeleteById(id: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGet(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetById(id: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetPage(page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetPage(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInsertDto} [companyInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyInsert(companyInsertDto?: CompanyInsertDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyInsert(companyInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyUpdateDto} [companyUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdate(companyUpdateDto?: CompanyUpdateDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdate(companyUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GatewayApi - axios parameter creator
 * @export
 */
export const GatewayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gatewayDeleteById', 'id', id)
            const localVarPath = `/Gateway/Delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gatewayGetById', 'id', id)
            const localVarPath = `/Gateway/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [siteId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayGetPage: async (siteId?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Gateway/GetPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GatewayInsertDto} [gatewayInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayInsert: async (gatewayInsertDto?: GatewayInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Gateway/Insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gatewayInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GatewayUpdateDto} [gatewayUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayUpdate: async (gatewayUpdateDto?: GatewayUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Gateway/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gatewayUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayApi - functional programming interface
 * @export
 */
export const GatewayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GatewayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatewayDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatewayDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatewayGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatewayGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [siteId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatewayGetPage(siteId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatewayGetPage(siteId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GatewayInsertDto} [gatewayInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatewayInsert(gatewayInsertDto?: GatewayInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatewayInsert(gatewayInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GatewayUpdateDto} [gatewayUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatewayUpdate(gatewayUpdateDto?: GatewayUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatewayUpdate(gatewayUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GatewayApi - factory interface
 * @export
 */
export const GatewayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GatewayApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayDeleteById(id: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.gatewayDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayGetById(id: string, options?: any): AxiosPromise<GatewayApiResult> {
            return localVarFp.gatewayGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [siteId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayGetPage(siteId?: string, page?: number, perPage?: number, options?: any): AxiosPromise<GatewayIReadOnlyCollectionApiResult> {
            return localVarFp.gatewayGetPage(siteId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GatewayInsertDto} [gatewayInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayInsert(gatewayInsertDto?: GatewayInsertDto, options?: any): AxiosPromise<GatewayApiResult> {
            return localVarFp.gatewayInsert(gatewayInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GatewayUpdateDto} [gatewayUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayUpdate(gatewayUpdateDto?: GatewayUpdateDto, options?: any): AxiosPromise<GatewayApiResult> {
            return localVarFp.gatewayUpdate(gatewayUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GatewayApi - object-oriented interface
 * @export
 * @class GatewayApi
 * @extends {BaseAPI}
 */
export class GatewayApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public gatewayDeleteById(id: string, options?: AxiosRequestConfig) {
        return GatewayApiFp(this.configuration).gatewayDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public gatewayGetById(id: string, options?: AxiosRequestConfig) {
        return GatewayApiFp(this.configuration).gatewayGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [siteId] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public gatewayGetPage(siteId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return GatewayApiFp(this.configuration).gatewayGetPage(siteId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GatewayInsertDto} [gatewayInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public gatewayInsert(gatewayInsertDto?: GatewayInsertDto, options?: AxiosRequestConfig) {
        return GatewayApiFp(this.configuration).gatewayInsert(gatewayInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GatewayUpdateDto} [gatewayUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public gatewayUpdate(gatewayUpdateDto?: GatewayUpdateDto, options?: AxiosRequestConfig) {
        return GatewayApiFp(this.configuration).gatewayUpdate(gatewayUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GatewayConfigApi - axios parameter creator
 * @export
 */
export const GatewayConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayConfigGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gatewayConfigGetById', 'id', id)
            const localVarPath = `/GatewayConfig/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayConfigApi - functional programming interface
 * @export
 */
export const GatewayConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GatewayConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatewayConfigGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayConfigApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatewayConfigGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GatewayConfigApi - factory interface
 * @export
 */
export const GatewayConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GatewayConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatewayConfigGetById(id: string, options?: any): AxiosPromise<GatewayConfigApiResult> {
            return localVarFp.gatewayConfigGetById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GatewayConfigApi - object-oriented interface
 * @export
 * @class GatewayConfigApi
 * @extends {BaseAPI}
 */
export class GatewayConfigApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayConfigApi
     */
    public gatewayConfigGetById(id: string, options?: AxiosRequestConfig) {
        return GatewayConfigApiFp(this.configuration).gatewayConfigGetById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeApi - axios parameter creator
 * @export
 */
export const NodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllById', 'id', id)
            const localVarPath = `/GetAll/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeDeleteById', 'id', id)
            const localVarPath = `/Node/Delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeGetById', 'id', id)
            const localVarPath = `/Node/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeGetLastEventById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeGetLastEventById', 'id', id)
            const localVarPath = `/Node/GetLastEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeGetPage: async (nodeId?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Node/GetPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeInsertDto} [nodeInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeInsert: async (nodeInsertDto?: NodeInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Node/Insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeUpdateDto} [nodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeUpdate: async (nodeUpdateDto?: NodeUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Node/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeApi - functional programming interface
 * @export
 */
export const NodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeWithCurrentDataIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeGetLastEventById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeEventApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeGetLastEventById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeGetPage(nodeId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeEventIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeGetPage(nodeId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeInsertDto} [nodeInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeInsert(nodeInsertDto?: NodeInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeInsert(nodeInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeUpdateDto} [nodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeUpdate(nodeUpdateDto?: NodeUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeUpdate(nodeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeApi - factory interface
 * @export
 */
export const NodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllById(id: string, options?: any): AxiosPromise<NodeWithCurrentDataIReadOnlyCollectionApiResult> {
            return localVarFp.getAllById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeDeleteById(id: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.nodeDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeGetById(id: string, options?: any): AxiosPromise<NodeApiResult> {
            return localVarFp.nodeGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeGetLastEventById(id: string, options?: any): AxiosPromise<NodeEventApiResult> {
            return localVarFp.nodeGetLastEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeGetPage(nodeId?: string, page?: number, perPage?: number, options?: any): AxiosPromise<NodeEventIReadOnlyCollectionApiResult> {
            return localVarFp.nodeGetPage(nodeId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeInsertDto} [nodeInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeInsert(nodeInsertDto?: NodeInsertDto, options?: any): AxiosPromise<NodeApiResult> {
            return localVarFp.nodeInsert(nodeInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeUpdateDto} [nodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeUpdate(nodeUpdateDto?: NodeUpdateDto, options?: any): AxiosPromise<NodeApiResult> {
            return localVarFp.nodeUpdate(nodeUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeApi - object-oriented interface
 * @export
 * @class NodeApi
 * @extends {BaseAPI}
 */
export class NodeApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public getAllById(id: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).getAllById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public nodeDeleteById(id: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).nodeDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public nodeGetById(id: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).nodeGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public nodeGetLastEventById(id: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).nodeGetLastEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [nodeId] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public nodeGetPage(nodeId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).nodeGetPage(nodeId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeInsertDto} [nodeInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public nodeInsert(nodeInsertDto?: NodeInsertDto, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).nodeInsert(nodeInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeUpdateDto} [nodeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public nodeUpdate(nodeUpdateDto?: NodeUpdateDto, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).nodeUpdate(nodeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeConfigApi - axios parameter creator
 * @export
 */
export const NodeConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeConfigGetById', 'id', id)
            const localVarPath = `/NodeConfig/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeConfigApi - functional programming interface
 * @export
 */
export const NodeConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeConfigApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeConfigApi - factory interface
 * @export
 */
export const NodeConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigGetById(id: string, options?: any): AxiosPromise<NodeConfigApiResult> {
            return localVarFp.nodeConfigGetById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeConfigApi - object-oriented interface
 * @export
 * @class NodeConfigApi
 * @extends {BaseAPI}
 */
export class NodeConfigApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigApi
     */
    public nodeConfigGetById(id: string, options?: AxiosRequestConfig) {
        return NodeConfigApiFp(this.configuration).nodeConfigGetById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeEventApi - axios parameter creator
 * @export
 */
export const NodeEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [deviceId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeEventGetPage: async (deviceId?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/NodeEvent/GetPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeEventApi - functional programming interface
 * @export
 */
export const NodeEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [deviceId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeEventGetPage(deviceId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeEventIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeEventGetPage(deviceId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeEventApi - factory interface
 * @export
 */
export const NodeEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeEventApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [deviceId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeEventGetPage(deviceId?: string, page?: number, perPage?: number, options?: any): AxiosPromise<NodeEventIReadOnlyCollectionApiResult> {
            return localVarFp.nodeEventGetPage(deviceId, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeEventApi - object-oriented interface
 * @export
 * @class NodeEventApi
 * @extends {BaseAPI}
 */
export class NodeEventApi extends BaseAPI {
    /**
     * 
     * @param {string} [deviceId] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeEventApi
     */
    public nodeEventGetPage(deviceId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return NodeEventApiFp(this.configuration).nodeEventGetPage(deviceId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('siteDeleteById', 'id', id)
            const localVarPath = `/Site/Delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetAllEvents: async (dashboardFilter?: DashboardFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/GetAllEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dashboardFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('siteGetById', 'id', id)
            const localVarPath = `/Site/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetCurrent: async (dashboardFilter?: DashboardFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/GetCurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dashboardFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetDashboard: async (dashboardFilter?: DashboardFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/GetDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dashboardFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetIssues: async (dashboardFilter?: DashboardFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/GetIssues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dashboardFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetPage: async (id?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/GetPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SiteInsertDto} [siteInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteInsert: async (siteInsertDto?: SiteInsertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/Insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SiteUpdateDto} [siteUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdate: async (siteUpdateDto?: SiteUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeStat} [nodeStat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdateEventNote: async (nodeStat?: NodeStat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Site/UpdateEventNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeStat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGetAllEvents(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeStatIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGetAllEvents(dashboardFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGetCurrent(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeStatIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGetCurrent(dashboardFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGetDashboard(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeStatIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGetDashboard(dashboardFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGetIssues(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeStatIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGetIssues(dashboardFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteGetPage(id?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteIReadOnlyCollectionApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteGetPage(id, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SiteInsertDto} [siteInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteInsert(siteInsertDto?: SiteInsertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteInsert(siteInsertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SiteUpdateDto} [siteUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteUpdate(siteUpdateDto?: SiteUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteUpdate(siteUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeStat} [nodeStat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteUpdateEventNote(nodeStat?: NodeStat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteUpdateEventNote(nodeStat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteDeleteById(id: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.siteDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGet(options?: any): AxiosPromise<SiteIReadOnlyCollectionApiResult> {
            return localVarFp.siteGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetAllEvents(dashboardFilter?: DashboardFilter, options?: any): AxiosPromise<NodeStatIReadOnlyCollectionApiResult> {
            return localVarFp.siteGetAllEvents(dashboardFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetById(id: string, options?: any): AxiosPromise<SiteApiResult> {
            return localVarFp.siteGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetCurrent(dashboardFilter?: DashboardFilter, options?: any): AxiosPromise<NodeStatIReadOnlyCollectionApiResult> {
            return localVarFp.siteGetCurrent(dashboardFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetDashboard(dashboardFilter?: DashboardFilter, options?: any): AxiosPromise<NodeStatIReadOnlyCollectionApiResult> {
            return localVarFp.siteGetDashboard(dashboardFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardFilter} [dashboardFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetIssues(dashboardFilter?: DashboardFilter, options?: any): AxiosPromise<NodeStatIReadOnlyCollectionApiResult> {
            return localVarFp.siteGetIssues(dashboardFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteGetPage(id?: string, page?: number, perPage?: number, options?: any): AxiosPromise<SiteIReadOnlyCollectionApiResult> {
            return localVarFp.siteGetPage(id, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SiteInsertDto} [siteInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteInsert(siteInsertDto?: SiteInsertDto, options?: any): AxiosPromise<SiteApiResult> {
            return localVarFp.siteInsert(siteInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SiteUpdateDto} [siteUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdate(siteUpdateDto?: SiteUpdateDto, options?: any): AxiosPromise<SiteApiResult> {
            return localVarFp.siteUpdate(siteUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeStat} [nodeStat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteUpdateEventNote(nodeStat?: NodeStat, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.siteUpdateEventNote(nodeStat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteDeleteById(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGet(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardFilter} [dashboardFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGetAllEvents(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGetAllEvents(dashboardFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGetById(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardFilter} [dashboardFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGetCurrent(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGetCurrent(dashboardFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardFilter} [dashboardFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGetDashboard(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGetDashboard(dashboardFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardFilter} [dashboardFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGetIssues(dashboardFilter?: DashboardFilter, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGetIssues(dashboardFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteGetPage(id?: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteGetPage(id, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SiteInsertDto} [siteInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteInsert(siteInsertDto?: SiteInsertDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteInsert(siteInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SiteUpdateDto} [siteUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteUpdate(siteUpdateDto?: SiteUpdateDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteUpdate(siteUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeStat} [nodeStat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteUpdateEventNote(nodeStat?: NodeStat, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteUpdateEventNote(nodeStat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (email?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['Password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [email] 
         * @param {string} [name] 
         * @param {string} [password] 
         * @param {string} [salt] 
         * @param {string} [roles] 
         * @param {string} [lastLogin] 
         * @param {number} [failedAttempts] 
         * @param {string} [unlockDate] 
         * @param {string} [createdDate] 
         * @param {Array<CompanyUser>} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (userId?: string, email?: string, name?: string, password?: string, salt?: string, roles?: string, lastLogin?: string, failedAttempts?: number, unlockDate?: string, createdDate?: string, companies?: Array<CompanyUser>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (password !== undefined) {
                localVarQueryParameter['Password'] = password;
            }

            if (salt !== undefined) {
                localVarQueryParameter['Salt'] = salt;
            }

            if (roles !== undefined) {
                localVarQueryParameter['Roles'] = roles;
            }

            if (lastLogin !== undefined) {
                localVarQueryParameter['LastLogin'] = (lastLogin as any instanceof Date) ?
                    (lastLogin as any).toISOString() :
                    lastLogin;
            }

            if (failedAttempts !== undefined) {
                localVarQueryParameter['FailedAttempts'] = failedAttempts;
            }

            if (unlockDate !== undefined) {
                localVarQueryParameter['UnlockDate'] = (unlockDate as any instanceof Date) ?
                    (unlockDate as any).toISOString() :
                    unlockDate;
            }

            if (createdDate !== undefined) {
                localVarQueryParameter['CreatedDate'] = (createdDate as any instanceof Date) ?
                    (createdDate as any).toISOString() :
                    createdDate;
            }

            if (companies) {
                localVarQueryParameter['Companies'] = companies;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(email?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [email] 
         * @param {string} [name] 
         * @param {string} [password] 
         * @param {string} [salt] 
         * @param {string} [roles] 
         * @param {string} [lastLogin] 
         * @param {number} [failedAttempts] 
         * @param {string} [unlockDate] 
         * @param {string} [createdDate] 
         * @param {Array<CompanyUser>} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(userId?: string, email?: string, name?: string, password?: string, salt?: string, roles?: string, lastLogin?: string, failedAttempts?: number, unlockDate?: string, createdDate?: string, companies?: Array<CompanyUser>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(userId, email, name, password, salt, roles, lastLogin, failedAttempts, unlockDate, createdDate, companies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(email?: string, password?: string, options?: any): AxiosPromise<AuthorizationApiResult> {
            return localVarFp.authorize(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [email] 
         * @param {string} [name] 
         * @param {string} [password] 
         * @param {string} [salt] 
         * @param {string} [roles] 
         * @param {string} [lastLogin] 
         * @param {number} [failedAttempts] 
         * @param {string} [unlockDate] 
         * @param {string} [createdDate] 
         * @param {Array<CompanyUser>} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(userId?: string, email?: string, name?: string, password?: string, salt?: string, roles?: string, lastLogin?: string, failedAttempts?: number, unlockDate?: string, createdDate?: string, companies?: Array<CompanyUser>, options?: any): AxiosPromise<AuthorizationApiResult> {
            return localVarFp.register(userId, email, name, password, salt, roles, lastLogin, failedAttempts, unlockDate, createdDate, companies, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @param {string} [email] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public authorize(email?: string, password?: string, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).authorize(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [email] 
     * @param {string} [name] 
     * @param {string} [password] 
     * @param {string} [salt] 
     * @param {string} [roles] 
     * @param {string} [lastLogin] 
     * @param {number} [failedAttempts] 
     * @param {string} [unlockDate] 
     * @param {string} [createdDate] 
     * @param {Array<CompanyUser>} [companies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public register(userId?: string, email?: string, name?: string, password?: string, salt?: string, roles?: string, lastLogin?: string, failedAttempts?: number, unlockDate?: string, createdDate?: string, companies?: Array<CompanyUser>, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).register(userId, email, name, password, salt, roles, lastLogin, failedAttempts, unlockDate, createdDate, companies, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDeleteById', 'id', id)
            const localVarPath = `/User/Delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGetById', 'id', id)
            const localVarPath = `/User/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [sort] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetPage: async (page?: number, perPage?: number, sort?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/GetPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInsert: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [sort] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetPage(page?: number, perPage?: number, sort?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetPage(page, perPage, sort, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInsert(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInsert(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteById(id: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.userDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: any): AxiosPromise<UserListApiResult> {
            return localVarFp.userGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetById(id: string, options?: any): AxiosPromise<UserApiResult> {
            return localVarFp.userGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [sort] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetPage(page?: number, perPage?: number, sort?: string, filter?: string, options?: any): AxiosPromise<UserListApiResult> {
            return localVarFp.userGetPage(page, perPage, sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInsert(user?: User, options?: any): AxiosPromise<UserApiResult> {
            return localVarFp.userInsert(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(user?: User, options?: any): AxiosPromise<UserApiResult> {
            return localVarFp.userUpdate(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeleteById(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetById(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {string} [sort] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetPage(page?: number, perPage?: number, sort?: string, filter?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetPage(page, perPage, sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInsert(user?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userInsert(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(user?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(user, options).then((request) => request(this.axios, this.basePath));
    }
}


